import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ModalService, ModalWithUid } from './modal.service';

@Component({
  selector: 'app-test',
  template: `
    <ng-container *ngFor="let meta of components; index as i; trackBy:trackByFn">
      <app-modal [meta]="meta" (onClose)="handleClose(i)"></app-modal>
    </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestComponent {
  components: ModalWithUid<any>[] = [];
  private uidCounter = 0;

  constructor(
    private readonly modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.modalService.newModalSubject.subscribe(modal => {
      this.components.push({
        ...modal,
        uid: ++this.uidCounter
      });
      this.cdr.detectChanges();
    })
  }

  trackByFn(_index: number, item: ModalWithUid<any>): number {
    return item.uid;
  }

  handleClose(index: number): void {
    this.components.splice(index, 1);
    this.cdr.detectChanges();
  }
}
