<div class="flex-center msg" [ngClass]="toast.isShown ? 'v' : ''">
  <svg style="width:1.1rem;height:1.1rem;margin-right:1.1rem;flex-shrink:0" [icon]="toast.icon"
       [style.color]='toast.color'></svg>
  <div class="txt">
    <div *ngFor="let p of toast.message" [innerText]="p"></div>
  </div>
  <div class="close" (click)="toast.hide()">
    <svg class="a-center" [icon]="'cross'"></svg>
  </div>
</div>
