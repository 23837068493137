<div *ngIf="displayed" class="a-overlay" [ngClass]="isShown? '' : 'hidden'">
  <div class="full inner-overlay"></div>
  <div class="window">
    <div class="full window-wrapper">
      <div class="header flex items-center justify-between">
        <div [innerText]="question.title"></div>
        <svg *ngIf="!hasButtons()" class="cross-closer h-6 w-6 cursor-pointer opacity-50" icon="cross" (click)="answer(false)"></svg>
      </div>
      <div class="question">
        <div [innerHTML]="question.questionHtml"></div>
        <div *ngIf="question.input" class="py-4">
          <ng-input [label]="question.input.label" [type]="question.input.type" [(value)]="value"></ng-input>
        </div>
      </div>
      <div class="footer" *ngIf="hasButtons()">
        <div class="btn btn-cancel w-full mr-2" (click)="answer(false)" [innerText]="question.cancelText"></div>
        <div class="btn w-full ml-2" [ngClass]="question.questionClass" (click)="answer(value ?? true)" [innerText]="question.submitText"></div>
      </div>
    </div>
  </div>
</div>
