import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { CrmGuard } from '@crm/crm.guard';
import { ModalModule } from '../shared/modal/modal.module';
import { QuestionComponent } from '@standalone/question/question.component';
import { OptionsModule } from '@standalone/options-ddm/options.module';
import { ToastComponent } from '@ui/toast/toast.component';
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutingModule, RouterOutlet, BrowserModule, HttpClientModule, ModalModule, QuestionComponent, OptionsModule, ToastComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CrmGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
