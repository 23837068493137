import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Question, QuestionService } from './question.service';
import { NgClass, NgIf } from "@angular/common";
import { IconDirective } from '../icon/icon.directive';
import { InputComponent } from '@standalone/input/input.component';

@Component({
  selector: 'ng-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    IconDirective,
    InputComponent
  ]
})
export class QuestionComponent implements OnDestroy {

  question!: Question;
  isShown = false;
  displayed = false;
  value: any;

  private readonly subscription;

  constructor(
    public service: QuestionService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.service.question.subscribe(q => this.show(q));
  }

  show(q: Question): void {
    this.value = q.input?.defaultValue ?? null;
    this.question = q;
    this.displayed = true;
    this.cdr.detectChanges();
    requestAnimationFrame(() => {
      this.isShown = true;
      this.cdr.markForCheck();
    });
  }

  answer(answer: boolean): void {
    this.isShown = false;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.displayed = false
      this.cdr.markForCheck();
    }, 300);
    this.service.answer.next(answer);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hasButtons(): boolean {
    return !!this.question.cancelText.length && !!this.question.submitText.length
  }
}
