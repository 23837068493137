import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ApplicationConfig } from '../environments/application.config';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isExternal = req.url.startsWith('http');

    const authReq = !isExternal
      ? req.clone({
        headers: req.headers
          .set(ApplicationConfig.AUTH_HEADER, 'Bearer ' + this.authService.token)
          .set(ApplicationConfig.ORGANIZATION_HEADER, this.authService.orgId ?? '')
          .set(ApplicationConfig.FORM_HEADER, this.authService.formId ?? ''),
        url: ApplicationConfig.API_ENDPOINT + req.url
      })
      : req;

    if (isExternal) {
      return next.handle(authReq);
    }

    return next.handle(authReq).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            const renewedToken = event.headers.get(ApplicationConfig.TOKEN_RENEW_HEADER);
            if (renewedToken) {
              this.authService.token = renewedToken;
            }
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.router.navigate(['/sign-in']);
            }
          }
        }
      })
    )
  }
}
