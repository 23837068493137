import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CrmGuard } from '@crm/crm.guard';

const routes: Routes = [
  {
    path: 'sign-in',
    loadComponent: () => import('../crm/pages/sign-in/sign-in.page').then(c => c.SignInPage)
  },
  {
    path: 'crm',
    loadChildren: () => import('../crm/crm.module').then(m => m.CrmModule),
    loadComponent: () => import('../crm/crm.page').then(m => m.CrmPage),
    canActivate: [CrmGuard],
  },
  {
    path: 'w',
    pathMatch: 'prefix',
    loadChildren: () => import('../public/submission-widget/client-widget.module').then(m => m.ClientWidgetModule),
  },
  {
    path: 'public',
    pathMatch: 'prefix',
    loadChildren: () => import('../public/admission-widget/admission-widget.module').then(c => c.AdmissionWidgetModule)
  },
  {
    path: 'form',
    pathMatch: 'prefix',
    loadChildren: () => import('../public/form-widget/form-widget.module').then(c => c.FormWidgetModule)
  },
  { path: '**', redirectTo: 'crm' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
