import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { ModalDirective } from './modal.directive';
import { ModalContent } from './modal.content';
import { ModalWithUid } from './modal.service';


const defaultAnimation = 'transform:translateY(-80%) scale(1.3);opacity:0;';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent<T extends ModalContent> implements OnInit {

  @ViewChild(ModalDirective, { static: true }) contentComponent!: ModalDirective;
  @Output() onClose = new EventEmitter<void>();

  @Input() meta!: ModalWithUid<T>;
  private duration = 300;
  private componentRef?: ComponentRef<T>;

  get uid(): string {
    return `modal-${this.meta.uid}`;
  }

  ngOnInit(): void {
    this.duration = this.meta.durationMs || 300;
    this.show(this.meta.component, this.meta.data || {});
    this.openFullScreen();
  }

  show(component: Type<T>, data: Partial<Record<keyof T, T[keyof T]>>): void {
    this.contentComponent.viewContainerRef.clear();
    this.componentRef = this.contentComponent.viewContainerRef.createComponent(component);
    Object.keys(data).forEach(key => {
      this.componentRef!.instance[key as keyof T] = data[key as keyof T]!;
    });
    this.componentRef.instance.modalWrapper = this;
  }

  constructor(private cdr?: ChangeDetectorRef) {}

  isVisible = false; // immediately after open triggered
  isCalculated = false;
  isFullScreen = false;
  isFinished = false;

  isNew = false;

  private rectStyle = '';
  private innerStyle = '';
  private animationsMap: Record<string, string> = {};

  openFullScreen(force = false): void {
    if (!this.isVisible) {
      this.componentRef?.instance?.beforeOpen();
      this.isVisible = true;
      this.cdr?.detectChanges();
      // setTimeout(() => requestAnimationFrame(() => this.openFullScreen(force)), 10000);
      requestAnimationFrame(() => this.openFullScreen(force));
      return;
    }

    else if (!this.isCalculated) {
      this.prepareElements();
      this.calculateDimensions(force);
      this.rectStyle += 'transition:none;';
      this.isCalculated = true;
      if (innerWidth >= 600) {
        // hide if desktop
        this.meta.onCalculatedStatus?.(true);
      }
      this.cdr?.markForCheck();
      // setTimeout(() => requestAnimationFrame(() => this.openFullScreen(force)), 10000);
      requestAnimationFrame(() => this.openFullScreen(force));
    }

    else if (!this.isFullScreen) {
      this.isFullScreen = true;
      setTimeout(() => {
        this.isFinished = true;
        this.animate();
        this.componentRef?.instance?.afterOpen();
      }, this.duration);
    }

    this.animate();
  }

  private _wrapper?: HTMLDivElement;
  private overlay?: HTMLDivElement;
  private popup?: HTMLDivElement;
  private content?: HTMLDivElement;

  private get wrapper(): HTMLDivElement {
    if (!this._wrapper) {
      this._wrapper = document.getElementById(this.uid)! as HTMLDivElement;
    }
    return this._wrapper;
  }

  private prepareElements(): void {
    if (this.isVisible && !this.popup) {
      this.overlay = this.wrapper?.querySelector('.overlay')!;
      this.popup = this.wrapper?.querySelector('.entity-card')!;
      this.content = this.popup?.querySelector('.card-inner')!;
      if (this.overlay) {
        this.overlay.style.transition = `opacity ${this.duration}ms`;
      }
    }
  }

  private transX = 0;
  private transY = 0;
  private scaleX = 0;
  private scaleY = 0;

  private transformOffset: [number, number] = [0, 0];
  private initialPos: [number, number] = [0, 0];
  private targetPos: [number, number] = [0, 0];

  private calculateDimensions(force = false): void {
    const { fromElement, fromRect, initialOpacity } = (this.meta || {});
    const element = fromElement?.();
    const pos2 = this.content?.getBoundingClientRect();
    if (!force && pos2 && (element || fromRect)) {
      const pos1 = element
        ? element.getBoundingClientRect()
        : fromRect!;

      [this.scaleX, this.scaleY] =
        [pos1.width / pos2.width, pos1.height / pos2.height];

      this.transformOffset = [
        (pos2.width - pos1.width) / 2,
        (pos2.height - pos1.height) / 2
      ];

      this.initialPos = [pos1.x, pos1.y];
      this.targetPos = [pos2.x, pos2.y];

      const scaledPos = {
        width: pos1.width,
        height: pos1.height,
        x: pos2.x + this.transformOffset[0],
        y: pos2.y + this.transformOffset[1],
      };
      this.transX = pos1.x - scaledPos.x;
      this.transY = pos1.y - scaledPos.y;
      // (absolute) const transY = this.transY + (pos1.height - pos2.height) * .5;
      // this.rectStyle = `width:${pos1.width}px;height:${pos1.height}px;transform:translate(${transX}px,${transY}px);`;
      this.rectStyle = `transform:translate(${this.transX}px,${this.transY}px)scale(${this.scaleX}, ${this.scaleY});opacity:${initialOpacity || 1};`;
      // (absolute) this.rectStyle = `width:${pos1.width}px;height:${pos1.height}px;transform:translate(calc(${transX}px - 50%),calc(${transY}px - 50%));`;
    } else {
      this.rectStyle = defaultAnimation;
    }
    this.innerStyle = `height:${pos2?.height}px;`;

    this.animate();

    if (!force && this.meta.animations?.length) {
      this.calculateAnimatedElements()
    }
  }

  recalculateHeight(force?: boolean): void {
    const pos = this.wrapper.querySelector('.card-inner')?.getBoundingClientRect();
    if (!pos) {
      return;
    }
    this.innerStyle = `height:${pos.height}px`;
    if (force && this.popup) {
      this.popup.style.height = `${pos.height}px`;
      this.popup.style.transition = 'none';
    } else {
      this.animate();
    }
  }

  changeWidth(rem: string | number): void {
    this.meta.width = rem;
    this.cdr?.markForCheck();
  }

  private animate(collapse = false): void {
    const isMobile = innerWidth < 600;
    if (this.overlay && this.popup && this.content && this._wrapper) {
      this.overlay.style.opacity = this.isFullScreen ? '1' : '0';
      this.popup.style.cssText = this.getDefaultInnerStyle() + this.getRectStyle(isMobile);
      this.popup.classList[this.isFullScreen ? 'add' : 'remove']('card-full');
      this.popup.classList[this.isFinished ? 'add' : 'remove']('open');
      if (this.isCalculated) {
        this._wrapper.style.opacity = '1';
      }

      if (!isMobile) {
        const initialOpacity = this.meta?.initialOpacity ?? 1;
        this.content.style.opacity = this.isFinished ? '1' : String(initialOpacity);
        if (initialOpacity > 0) {
          this.popup.querySelectorAll<HTMLDivElement>('.scale-animation').forEach(el => {
            const scaleAttribute = +(el.getAttribute('initial-scale') ?? '1');
            el.style.transition = `transform ${this.duration}ms linear`;
            el.style.transform = (this.isCalculated && !this.isFullScreen)
              ? `scale(${scaleAttribute / this.scaleX}, ${scaleAttribute / this.scaleY})`
              : '';
          })
          this.popup.querySelectorAll<HTMLDivElement>('.transparent-animation').forEach(el => {
            el.style.opacity = this.isFinished ? '1' : '0';
            el.style.transition = this.isFullScreen ? 'opacity .1s' : 'none';
          });
          this.popup.querySelectorAll<HTMLDivElement>('.shadow-animation').forEach(el => {
            el.style.boxShadow = this.isFinished ? '' : 'none';
          });
        }
      }
    }
    else {
      console.warn('SKIP ANIMATE');
    }

    if (this.meta.animations && !isMobile) {
      this.applyAnimations(collapse);
    }

    if (this.componentRef?.instance) {
      this.componentRef.instance.isVisible = this.isVisible;
      this.componentRef.instance.isCalculated = this.isCalculated;
      this.componentRef.instance.isFullScreen = this.isFullScreen;
      this.componentRef.instance.isFinished = this.isFinished;
      this.componentRef.instance.onPopupStateChanged();
    }
  }

  private getRectStyle(isMobile: boolean): string {
    if (isMobile) {
      return `transition: transform ${this.duration}ms ease, height ${this.duration}ms;${this.innerStyle}`
    }
    return this.isFullScreen ? this.innerStyle :
      this.isCalculated ? this.rectStyle :
        'visibility:hidden;';
  }

  clearRect(): void {
    this.rectStyle = defaultAnimation;
  }

  collapse(force = false): void {
    this.rectStyle = this.rectStyle.replace('transition:none', '');
    if (!force) {
      this.calculateDimensions();
    }
    this.isFullScreen = this.isFinished = false
    setTimeout(() => {
      this.isVisible = this.isCalculated = false;
      this.meta.onCalculatedStatus?.(false);

      this.rectStyle = this.innerStyle = '';
      this.cdr?.markForCheck();
      this.removeElements();
      this.meta.onClose?.();
      this.onClose.emit();
    }, this.duration);
    this.beforeClose();

    this.animate(true);
  }

  private removeElements(): void {
    delete this.popup;
    delete this.content;
    delete this.overlay;
    this.elementsMap.clear();
  }

  /**
   * @deprecated
   */
  beforeClose() {}

  private calculateAnimatedElements(): void {
    const element = this.meta?.fromElement?.();
    if (element && this.popup && this.meta.animations?.length) {
      for (const item of this.meta.animations) {
        const from = element.querySelector<HTMLDivElement>(item.from);
        const to = this.getElement<HTMLDivElement>(item.to);
        console.log('from: ', item.from, from);
        console.log('to: ', item.to, to);
        if (!from || !to) {
          continue;
        }

        const pos1 = from.getBoundingClientRect();
        const pos2 = to.getBoundingClientRect() as DOMRect;

        const itemTop = this.initialPos[1] + (pos2.y - this.targetPos[1]) * this.scaleY;
        const itemLeft = this.initialPos[0] + (pos2.x - this.targetPos[0]) * this.scaleX;

        const scaledPos = {
          width: pos2.width * this.scaleX,
          height: pos2.height * this.scaleY,
          x: itemLeft,
          y: itemTop,
        };

        const transX = pos1.x - scaledPos.x + (pos1.width - scaledPos.width) * .5;
        const transY= pos1.y - scaledPos.y + (pos1.height - scaledPos.height) * .5;
        const scaleX = pos1.width / scaledPos.width;
        const scaleY = pos1.height / scaledPos.height;

        this.animationsMap[item.to] =
          `transform:translate(${transX / this.scaleX}px,${transY / this.scaleY}px) scale(${scaleX}, ${scaleY});`
          + (item.additionalStyle || '');
      }
    }
  }

  private elementsMap = new Map<string, Element>();

  private getElement<T extends Element>(selector: string): T | null {
    if (!this.popup) {
      return null;
    }
    let element = this.elementsMap.get(selector) as T | undefined | null;
    if (!element) {
      element = this.popup.querySelector<T>(selector);
      if (element) {
        this.elementsMap.set(selector, element);
      }
    }
    return element as T ?? null;
  }

  /** avatar animation part **/
  private applyAnimations(collapse = false): void {
    const transition = this.getAnimationTransition(collapse);
    for (const animation of this.meta.animations || []) {
      const element = this.getElement<HTMLDivElement>(animation.to);
      if (element) {
        const transform = this.getAnimationTransform(animation.to);
        element.style.cssText = ['overflow:hidden', 'display:block', transition, transform].join('; ');
      }
    }
  }

  private getAnimationTransition(collapse = false): string {
    const base = `transition: transform ${this.duration}ms ease, border-radius ${this.duration}ms`;
    return this.isFullScreen ? base :
      this.isCalculated ? (collapse ? base : '') :
        (collapse ? '' : base);
  }

  private getAnimationTransform(target: string): string {
    return (this.isCalculated && !this.isFullScreen) ? this.animationsMap[target] ?? '' : '';
  }

  getDefaultInnerStyle(): string {
    const w = this.getWidth();

    const styles = [
      `transition: ${this.duration}ms ease;`,
      `width: ${w};`
    ];
    Object.entries(this.meta.cardStyle || {})
      .forEach(([key, value]) => styles.push(`${key}: ${value};`));
    return styles.join('');
  }

  getWidth(): string {
    const w = this.meta.width;
    return typeof w === 'number' ? w + 'rem' : w || '38rem';
  }
}
