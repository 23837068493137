import { Component } from '@angular/core';
import { ToastService } from './toast.service';
import { NgClass, NgForOf, NgStyle } from '@angular/common';
import { IconDirective } from '@standalone/icon/icon.directive';

@Component({
  selector: 'app-toast',
  standalone: true,
  templateUrl: 'toast.component.html',
  imports: [
    NgClass,
    IconDirective,
    NgForOf,
    NgStyle
  ],
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  constructor(public toast: ToastService) {}
}
