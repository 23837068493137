import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class CrmGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const result = await this.authService.isTokenValid();
    if (!result.success) {
      this.router.navigate(['/sign-in']);
      return false;
    }
    return true;
  }

}
